import React from "react"
import { Link } from "gatsby"

export default function FourOhFour() {
  return (
    <>
      <h1>404: Not Found</h1>
      <p>
        Click <Link to="/">here</Link> to get back to safety!
      </p>
    </>
  )
}
